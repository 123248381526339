<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      v-if="!fetchinData"
    />

    <Breadcrumbs v-if="!fetchinData" :categoryUuid="data.uuid" />

    <v-row v-if="!fetchinData">
      <v-col v-if="readPerm">
        <v-card tile class="my-2">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Categories") }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              fab
              dark
              small
              color="green"
              class="white--text"
              :disabled="disabledByPermission(writePerm)"
              @click="addCategory()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <table class="default-table" v-if="data.subCategories.length > 0">
              <draggable
                v-model="data.subCategories"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                @start="dragging = true"
                @end="handleDragEnd('category')"
                :handle="disabledHandleByPermission(writePerm)"
              >
                <tr v-for="item in data.subCategories" :key="item.id">
                  <td class="iconOnly">
                    <v-icon
                      v-if="item.isOnline === '1'"
                      color="green"
                      @click="openCategory(item.uuid)"
                    >
                      mdi-folder
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey lighten-2"
                      @click="openCategory(item.uuid)"
                    >
                      mdi-folder
                    </v-icon>
                  </td>

                  <td>
                    <DataPatcher
                      :itemValue="item.name"
                      itemName="name"
                      :route="patchRoute + item.uuid"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>

                  <td class="iconOnly">
                    <v-btn
                      class="ma-1"
                      fab
                      dark
                      depressed
                      x-small
                      color="blue"
                      @click="openCategory(item.uuid)"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </td>

                  <td class="switchOnly">
                    <v-switch
                      class="mx-2"
                      v-model="item.isOnline"
                      color="green"
                      inset
                      false-value="0"
                      true-value="1"
                      @change="categoryOnlineSwitch(item.uuid, item.isOnline)"
                      :disabled="disabledByPermission(writePerm)"
                    ></v-switch>
                  </td>

                  <td class="iconOnly">
                    <v-btn
                      class="ma-1"
                      fab
                      dark
                      depressed
                      x-small
                      color="red"
                      :disabled="disabledByPermission(deletePerm)"
                      @click="deleteCategoryStart(item.uuid)"
                    >
                      <v-icon> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                  <td class="draghandle iconOnly">
                    <v-icon v-if="writePerm" class="ma-1">mdi-drag</v-icon>
                    <v-icon v-else disabled style="opacity: 0.5" class="ma-1">
                      mdi-drag
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </table>

            <span v-else>
              {{ $t("plugins.frontend.noCategoriesAvailable") }}
            </span>
          </v-card-text>
        </v-card>

        <v-card tile class="my-2">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Pages") }}</v-toolbar-title>

            <v-spacer />
            <v-btn
              fab
              dark
              small
              color="green"
              class="white--text"
              :disabled="disabledByPermission(writePerm)"
              @click="addPage()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <table class="default-table">
              <draggable
                v-model="data.pages"
                tag="tbody"
                ghost-class="ghost"
                :move="checkMove"
                draggable=".is-draggable"
                @start="dragging = true"
                @end="handleDragEnd('page')"
                :handle="disabledHandleByPermission(writePerm)"
              >
                <tr
                  v-for="item in data.pages"
                  :key="item.id"
                  :class="draggableClass(item.isCategoryStartPage)"
                >
                  <td class="iconOnly">
                    <v-icon
                      v-if="item.isOnline === '1'"
                      color="green"
                      @click="openPage(item.uuid)"
                    >
                      mdi-file-document
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey lighten-2"
                      @click="openPage(item.uuid)"
                    >
                      mdi-file-document
                    </v-icon>
                  </td>
                  <td>
                    <DataPatcher
                      v-if="item.isCategoryStartPage !== '1'"
                      :itemValue="item.name"
                      itemName="name"
                      :route="patchRoutePage + item.uuid"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                    <span v-else class="pa-3">
                      <!-- we cant edit name of category startpages -->
                      <em>{{ item.name }}</em>
                    </span>
                  </td>
                  <td class="iconOnly">
                    <v-btn
                      fab
                      depressed
                      x-small
                      color="blue"
                      class="white--text"
                      @click="openPage(item.uuid)"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </td>

                  <td class="switchOnly">
                    <v-switch
                      v-if="item.isCategoryStartPage !== '1'"
                      class="mx-2"
                      v-model="item.isOnline"
                      color="green"
                      inset
                      false-value="0"
                      true-value="1"
                      :disabled="disabledByPermission(writePerm)"
                      @change="pageOnlineSwitch(item.uuid, item.isOnline)"
                    ></v-switch>

                    <v-switch
                      v-else
                      class="mx-2"
                      v-model="item.isOnline"
                      color="green"
                      inset
                      false-value="0"
                      true-value="1"
                      :disabled="true"
                    ></v-switch>
                  </td>

                  <td class="iconOnly">
                    <v-btn
                      v-if="item.isCategoryStartPage !== '1'"
                      fab
                      depressed
                      x-small
                      color="red"
                      class="white--text"
                      :disabled="disabledByPermission(deletePerm)"
                      @click="deletePageStart(item.uuid)"
                    >
                      <v-icon> mdi-trash-can </v-icon>
                    </v-btn>

                    <v-btn v-else fab depressed x-small :disabled="true">
                      <v-icon> mdi-trash-can </v-icon>
                    </v-btn>
                  </td>
                  <td class="draghandle iconOnly">
                    <v-icon v-if="writePerm && item.isCategoryStartPage !== '1'"
                      >mdi-drag</v-icon
                    >
                    <v-icon v-else disabled style="opacity: 0.5">
                      mdi-drag
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-else v-html="$t('phrases.noPermissionsMessage')"></v-col>
    </v-row>

    <AreYouSureDialog
      :title="this.$t('dialogs.areYouSure')"
      :text="this.$t('dialogs.deleteInfo')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import Breadcrumbs from "@/components/plugins/frontend/Breadcrumbs";
import draggable from "vuedraggable";
export default {
  name: "CategoryView",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
    draggable,
    Breadcrumbs,
  },

  data() {
    return {
      categoryUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: null,
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      typeToDelete: null,
      patchRoute: null,
      patchRoutePage: null,
      draggedItemUuid: null,
    };
  },

  props: [],

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  methods: {
    async pageOnlineSwitch(uuid, value) {
      const route = `/company/${this.companyID}/frontend/${this.data.frontendUuid}/page/${uuid}`;

      const params = new URLSearchParams();
      params.append("isOnline", value);

      this.preloader = true;
      let response = await this.patch(`${route}`, params);
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async categoryOnlineSwitch(uuid, value) {
      const route = `/company/${this.companyID}/frontend/${this.data.frontendUuid}/category/${uuid}`;

      const params = new URLSearchParams();
      params.append("isOnline", value);

      this.preloader = true;
      let response = await this.patch(`${route}`, params);
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    mutateData(value) {
      console.log(value);
    },

    draggableClass(state) {
      if (state === "0") {
        return "is-draggable";
      } else {
        return "is-locked";
      }
    },

    deletePageStart(uuid) {
      this.itemToDelete = uuid;
      this.typeToDelete = "page";
      this.safeguardingDialog = true;
    },

    deleteCategoryStart(uuid) {
      this.itemToDelete = uuid;
      this.typeToDelete = "category";
      this.safeguardingDialog = true;
    },

    openCategory(uuid) {
      this.$router.push({
        name: "frontend.category.:id",
        params: { id: uuid },
      });
    },

    openPage(uuid) {
      this.$router.push({
        name: "frontend.page.:id",
        params: { id: uuid },
      });
    },

    async deletePage() {
      this.preloader = true;
      let response = await this.delete(
        `/company/${this.companyID}/frontend/${this.data.frontendUuid}/page/${this.itemToDelete}`
      );
      this.preloader = false;

      console.log(response.data.data);

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemDeletedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate
        // get index
        this.data.pages.forEach((item, index) => {
          if (item.uuid === this.itemToDelete) {
            this.data.pages.splice(index, 1);
          }
        });
      }
    },

    async deleteCategory() {
      this.preloader = true;
      let response = await this.delete(
        `/company/${this.companyID}/frontend/${this.data.frontendUuid}/category/${this.itemToDelete}`
      );
      this.preloader = false;

      console.log(response.data.data);

      if (response.status === 200) {
        if (response.data.data) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t(
            "phrases.itemDeletedMessage"
          );
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          // mutate
          // get index
          this.data.subCategories.forEach((item, index) => {
            if (item.uuid === this.itemToDelete) {
              this.data.subCategories.splice(index, 1);
            }
          });
        } else {
          // kann nicht gelöscht werden, da sich noch Pages/Sub-Kategorien in der Kategorie befinden
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t(
            "plugins.frontend.deleteCategoryFail"
          );
          this.$root.snackbarGlobal.snackbarColor = "red";
          this.$root.snackbarGlobal.snackbarTimeout = 8000;
        }
      }
    },

    async addCategory() {
      /* /company/{companyuuid}/frontend/{frontenduuid}/category */

      let formData = new FormData();
      formData.append("parentCategoryUuid", this.data.uuid);

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/frontend/${this.data.frontendUuid}/category`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.data.subCategories.push(response.data.data[0]); // mutate
    },

    async addPage() {
      /* /company/{companyuuid}/frontend/{frontenduuid}/page */

      let formData = new FormData();
      formData.append("categoryUuid", this.data.uuid);

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/frontend/${this.data.frontendUuid}/page`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.data.pages.push(response.data.data.result[0]); // mutate
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        if (this.typeToDelete === "page") {
          this.deletePage();
        } else if (this.typeToDelete === "category") {
          this.deleteCategory();
        }
      }
    },

    disabledHandleByPermission(value) {
      // .draghandle

      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/category/${this.categoryUuid}`
      ); // await data from mixin

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;

        if (this.data.name === "home") {
          this.pageTitle = "Home";
        } else {
          this.pageTitle = this.$t("phrases.Category") + ": " + this.data.name;
        }

        this.patchRoute = `/company/${store.getters["auth/session"].currentCompany.companyUuid}/frontend/${this.data.frontendUuid}/category/`;
        this.patchRoutePage = `/company/${store.getters["auth/session"].currentCompany.companyUuid}/frontend/${this.data.frontendUuid}/page/`;
      } else if (response.status === 403) {
        // no permission
      }
    },

    emittedTableEvent(obj) {
      if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    async createFrontend() {
      let response = await this.post(`company/${this.companyID}/frontend`); // await data from mixin

      let newItem = response.data.data.result[0];
      this.items.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Das Dokument wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async dragUpdate(idAndPrio, type) {
      if (type === "category") {
        const url = `/company/${this.companyID}/frontend/${this.data.frontendUuid}/category/${this.draggedItemUuid}/prio`;
        let response = await this.put(url, JSON.stringify(idAndPrio), "json"); // await data from mixin

        if (response.status === 200) {
          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Reihenfolge wurde erfolgreich geändert.";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.data.subCategories = response.data.data;
        }
      } else if (type === "page") {
        const url = `/company/${this.companyID}/frontend/${this.data.frontendUuid}/page/${this.draggedItemUuid}/prio`;
        let response = await this.put(url, JSON.stringify(idAndPrio), "json"); // await data from mixin

        if (response.status === 200) {
          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Reihenfolge wurde erfolgreich geändert.";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.data.pages = response.data.data;
        }
      }
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
      this.draggedItemUuid = e.draggedContext.element.uuid;
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;

      console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function (type) {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      let idAndPrio = [];
      let newOrderedArray = [];

      if (type === "category") {
        newOrderedArray = this.data.subCategories;
      } else if (type === "page") {
        newOrderedArray = this.data.pages;
      }

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio, type);
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
